define('tm3/pods/components/matter-conflict-check/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    // used to infer subject names from a case name
    var subjectSearch = /[\w\-'.]+, [\w\-'.]+/g; // `[last name], [first name]`

    exports.default = Ember.Component.extend({
        tagName: '',

        store: Ember.inject.service(),

        router: Ember.inject.service(),

        // eslint-disable-next-line ember/no-observers
        triggerSearch: Ember.observer('matter.{litigationType,name,plaintiff,defendant}', 'vsText', 'shouldSearch', function () {
            if (!this.get('shouldSearch')) {
                this.set('caseName', '');
                this.set('subjectNames', []);

                return;
            }

            var caseName = '';
            var subjectNames = [];

            switch (this.get('matter.litigationType')) {
                case 'N/A':
                    caseName = (this.get('matter.name') || '').trim().toLowerCase();

                    // Find possible subject names in case name (not perfect but good enough for our purposes).
                    subjectNames.push.apply(subjectNames, _toConsumableArray(caseName.matchAll(subjectSearch).map(function (match) {
                        return match[0];
                    })));

                    break;

                case 'Individual Case':
                    {
                        var plaintiff = (this.get('matter.plaintiff') || '').trim().toLowerCase();
                        var defendant = (this.get('matter.defendant') || '').trim().toLowerCase();
                        var vsText = this.get('vsText');

                        caseName = plaintiff + ' ' + vsText + ' ' + defendant;

                        if (plaintiff !== '') {
                            subjectNames.push(plaintiff);
                        }

                        if (defendant !== '') {
                            subjectNames.push(defendant);
                        }

                        break;
                    }

                default:
                    break;
            }

            this.set('caseName', caseName);
            this.set('subjectNames', subjectNames);
        }),

        moreLink: Ember.computed('caseName', function () {
            var name = this.get('caseName') || '';

            if (!name) {
                return null;
            }

            return this.get('router').urlFor('conflicts', {
                queryParams: {
                    type: 'subjects,cases',
                    name: name
                }
            });
        }),

        shouldSearch: Ember.computed('matter.{litigationType,name,plaintiff,defendant}', function () {
            switch (this.get('matter.litigationType')) {
                case 'N/A':
                    {
                        var caseName = (this.get('matter.name') || '').trim();

                        // don't search until we have a complete case name
                        return caseName.length >= 5;
                    }

                case 'Individual Case':
                    {
                        var plaintiff = (this.get('matter.plaintiff') || '').trim();
                        var defendant = (this.get('matter.defendant') || '').trim();

                        // don't search until we've filled both fields
                        return plaintiff.length >= 3 && defendant.length >= 3;
                    }
                default:
                    return false;
            }
        })
    });
});