define('tm3/pods/crm/deals/info/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error2.default, {
        session: Ember.inject.service(),
        notify: Ember.inject.service(),

        tag: null,

        // hold the model that we should connect to this note
        selectedItem: false,

        // support add note comp
        isAdding: false,

        /**
         * check if the form to upload should be displayed
         */
        showUploadBox: false,

        actions: {
            // support add note comp
            addNote: function addNote() {
                this.set('isAdding', true);
            },


            /**
             * hide connection form
             */
            hideConnectForm: function hideConnectForm() {
                Ember.$('#connectForm').addClass('hidden');
            },

            /**
             * show connection form
             */
            showConnectForm: function showConnectForm() {
                Ember.$('#connectForm').removeClass('hidden');
            },


            /**
             * hide todo form
             */
            hideTodoForm: function hideTodoForm() {
                Ember.$('#todoForm').addClass('hidden');
            },


            /**
             * show todo form
             */
            showTodoForm: function showTodoForm() {
                var newTodo = this.store.createRecord('todo', {
                    priority: 'Normal',
                    assignedTo: null
                });

                this.set('newTodo', newTodo);
                Ember.$('#todoForm').removeClass('hidden');
            },
            removeConnection: function removeConnection(connection) {
                connection.destroyRecord();
                this.get('notify').success('Connection removed');
            },


            /**
             * connect an existing contact to this crm note
             * @param item
             */
            newConnection: function newConnection(item) {
                var model = this.get('model');
                var self = this;
                var newObject = {
                    deal: model
                };

                // must be a contact
                var newConnectTo = this.store.peekRecord('client', item.id);
                newObject.client = newConnectTo;
                var newRecord = this.store.createRecord('deal-has-client', newObject);

                var clientsReference = this.get('clients').toArray();

                newRecord.save().then(function (data) {
                    self.get('notify').success('Connected Client to this opportunity');
                    clientsReference.pushObject(newRecord);
                    self.set('clients', clientsReference);
                    self.send('hideConnectForm');
                }, function (reason) {
                    self.validationReport(newRecord);
                });
            },


            /**
             * create a new todo record and tie to the current deal
             *
             * @param formData
             */
            afterAddTodo: function afterAddTodo(todo) {
                var deal = this.get('model');
                var todos = this.get('todos');
                var self = this;

                // todo is already saved, now connect deal to new task record
                var joinRecord = self.store.createRecord('deal-has-todo', { deal: deal, todo: todo });
                var todoReference = todos.toArray();

                joinRecord.save().then(function (data) {
                    self.get('notify').success('Created new todo for this opportunity');
                    todoReference.pushObject(joinRecord);
                    self.set('todos', todoReference);
                    self.send('hideTodoForm');
                }, function (reason) {
                    self.validationReport(joinRecord);
                });
            },


            /**
             * when the user types new values into the select box
             * this can also act as a place to perform custom filtering/munging/merging of API results
             * before pushing the final list to the autosuggest
             * @param inputVal
             */
            searchClient: function searchClient(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchClient, term, resolve, reject, 600);
                });
            },


            /**
             * upload actions emitted by x-upload-file
             */
            addFile: function addFile() {
                this.set('showUploadBox', true);
            },
            beforeUpload: function beforeUpload(files) {},
            afterUpload: function afterUpload(response) {
                var self = this;

                var files = [];
                if (Ember.isPresent(response.file)) {
                    files.pushObject(self.store.createRecord('file', response.file[0]));
                } else if (Ember.isPresent(response.files)) {
                    response.files.forEach(function (file) {
                        files.pushObject(self.store.createRecord('file', file));
                    });
                }
                var promises = [];
                files.forEach(function (file) {
                    promises.pushObject(self.store.createRecord('deal-has-file', {
                        deal: self.get('model'),
                        file: file
                    }).save());
                });

                Ember.RSVP.all(promises).then(function () {
                    self.get('notify').success('Files uploaded and linked to this Opportunity');
                    self.set('showUploadBox', false);
                    self.send('fetch');
                }, function (_error) {
                    self.get('notify').error('Failed to link files to opportunity');
                });
            },
            cancelUpload: function cancelUpload() {
                this.set('showUploadBox', false);
            },
            errorUpload: function errorUpload(_error) {},
            refreshNewNoteToDeal: function refreshNewNoteToDeal(newNote) {
                this.send('fetch');
            },
            saveFile: function saveFile(file) {
                var self = this;
                file.save().then(function () {
                    self.get('notify').success('Files updated successfully');
                }, function () {
                    self.validationReport(file);
                });
            }
        },

        _performSearchClient: function _performSearchClient(term, resolve, reject) {
            return this.store.query('client', {
                'first_name||last_name': '*' + term + '*',
                limit: 100,
                sortField: 'last_name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});