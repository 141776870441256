define('tm3/pods/matter/subjects/info/documents/controller', ['exports', 'ember-changeset', 'ember-lifeline/mixins/run'], function (exports, _emberChangeset, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Controller.extend(_run.default, {
        router: Ember.inject.service(),
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        showUploadForm: false,
        subjects: null,

        reportTabDocuments: Ember.computed('documents.[]', 'fileTypes.[]', 'documents.@each.tabNumber', function () {
            var documents = this.get('documents').filter(function (document) {
                return document.get('tabNumber') || document.get('docType') === 'REPORT TAB' || document.get('report') && document.get('docType') !== 'REPORT';
            });

            var sortedTypesNames = this.get('fileTypes').mapBy('value');

            // documents with tabNumber are sorted by tabNumber, else alphabetically
            documents.sort(function (a, b) {
                var aTabNumber = a.get('tabNumber');
                var bTabNumber = b.get('tabNumber');
                var aDocType = a.get('docType');
                var bDocType = b.get('docType');
                var aDocTypeIndex = sortedTypesNames.indexOf(aDocType);
                var bDocTypeIndex = sortedTypesNames.indexOf(bDocType);

                if (!Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber)) {
                    return aTabNumber < bTabNumber ? -1 : aTabNumber > bTabNumber ? 1 : a.get('name') < b.get('name') ? -1 : 1;
                }

                if (!Ember.isEmpty(aTabNumber) && Ember.isEmpty(bTabNumber)) {
                    return -1;
                }

                if (Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber)) {
                    return 1;
                }

                if (Ember.isEmpty(aTabNumber) && Ember.isEmpty(bTabNumber)) {
                    if (!Ember.isEmpty(aDocType) && Ember.isEmpty(bDocType) || !Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) && aDocTypeIndex < bDocTypeIndex) {
                        // Doc A is lower in order than Doc B based on doc type's order
                        return -1;
                    } else if (Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) || !Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) && aDocTypeIndex > bDocTypeIndex) {
                        // Doc A is higher in order than Doc B based on doc type
                        return 1;
                    } else {
                        // Doc A is equal in order to Doc B based on doc type - check against name now
                        return a.get('data.name') < b.get('data.name') ? -1 : 1;
                    }
                }
            });

            return documents;
        }),

        reportTabDocumentsSelected: Ember.computed('reportTabDocuments.@each.isSelectedForEdit', function () {
            return this.get('reportTabDocuments').every(function (doc) {
                return doc.get('isSelectedForEdit');
            });
        }),

        groupedReportTabDocuments: Ember.computed('reportTabDocuments', function () {
            var groupedByReports = this.get('reportTabDocuments').sortBy('nameOfReport').reduce(function (group, doc) {
                var reportName = doc.get('nameOfReport');

                group[reportName] = group[reportName] ? group[reportName] : [];
                group[reportName].push(doc);

                return group;
            }, {});

            if (Object.keys(groupedByReports).length > 1) {
                return groupedByReports;
            } else {
                return {
                    '': this.get('reportTabDocuments')
                };
            }
        }),

        groupedReportTabDocumentsSelected: Ember.computed('groupedReportTabDocuments', 'reportTabDocuments.@each.isSelectedForEdit', function () {
            var grouped = this.get('groupedReportTabDocuments');

            return Object.keys(grouped).reduce(function (acc, reportName) {
                return Object.assign(acc, _defineProperty({}, reportName, grouped[reportName].every(function (doc) {
                    return doc.get('isSelectedForEdit');
                })));
            }, {});
        }),

        reportDocuments: Ember.computed('documents.[]', 'reportTabDocuments', function () {
            var _this = this;

            return this.get('documents').filter(function (document) {
                return !_this.get('reportTabDocuments').includes(document);
            }).filter(function (document) {
                return document.get('docType') === 'REPORT';
            });
        }),

        reportDocumentsSelected: Ember.computed('reportDocuments.@each.isSelectedForEdit', function () {
            return this.get('reportDocuments').every(function (doc) {
                return doc.get('isSelectedForEdit');
            });
        }),

        nonReportTabDocuments: Ember.computed('documents.[]', 'reportTabDocuments', 'reportDocuments', function () {
            var _this2 = this;

            return this.get('documents').filter(function (document) {
                return !_this2.get('reportTabDocuments').includes(document) && !_this2.get('reportDocuments').includes(document);
            });
        }),

        groupedDocuments: Ember.computed('documents.[]', 'fileTypes.[]', 'nonReportTabDocuments.[]', function () {
            var sortedTypes = this.get('fileTypes'); // already sorted in route
            var sortedTypesNames = this.get('fileTypes').mapBy('value');
            var grouped = {};
            var nonReportTabDocuments = this.get('nonReportTabDocuments');
            var noTypeDocs = nonReportTabDocuments.filterBy('docType', null);

            grouped['no-type'] = noTypeDocs;
            sortedTypes.forEach(function (type) {
                var typeName = type.get('value');
                var docs = nonReportTabDocuments.filterBy('docType', typeName).sortBy('name');

                if (docs.length) {
                    grouped[typeName] = docs;
                }
            });

            // documents with docTypes that are not found in the fileTypes go under 'unclassified'
            var unclassifiedDocuments = nonReportTabDocuments.filter(function (document) {
                return document.get('docType') === 'undefined' || sortedTypesNames.indexOf(document.get('docType')) < 0;
            });

            if (unclassifiedDocuments.get('length')) {
                if (grouped['UNCLASSIFIED']) {
                    grouped['UNCLASSIFIED'].push(unclassifiedDocuments);
                } else {
                    grouped['UNCLASSIFIED'] = unclassifiedDocuments;
                }
            }

            return grouped;
        }),

        groupedDocumentsSelected: Ember.computed('groupedDocuments', 'documents.@each.isSelectedForEdit', function () {
            var grouped = this.get('groupedDocuments');

            return Object.keys(grouped).reduce(function (acc, docType) {
                return Object.assign(acc, _defineProperty({}, docType, grouped[docType].every(function (doc) {
                    return doc.get('isSelectedForEdit');
                })));
            }, {});
        }),

        hasOcrDocuments: Ember.computed('documents.[]', function () {
            return this.get('documents').filterBy('ocrIndexStatus', 'done').length > 0;
        }),

        documentSelection: Ember.computed('documents.@each.isSelectedForEdit', {
            get: function get() {
                var docs = this.get('documents');

                return docs.filter(function (item) {
                    return item.get('isSelectedForEdit') === true;
                });
            },
            set: function set(key, value) {
                // setting the documentSelection
                var items = this.get('documents');

                items.forEach(function (item) {
                    item.set('isSelectedForEdit', undefined);
                });

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = value[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var item = _step.value;

                        item.set('isSelectedForEdit', true);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                return value;
            }
        }),
        documentSelectionCopy: Ember.computed.map('documentSelection', function (doc) {
            doc.set('post', this.get('posts').findBy('documentResource.id', doc.get('id')));

            return new _emberChangeset.default(doc);
        }),

        sortedDocumentSelectionCopy: Ember.computed('documentSelectionCopy', 'fileTypes.[]', function () {
            var _this3 = this;

            var sortedList = this.get('documentSelectionCopy').sort(function (a, b) {
                var aTabNumber = a.get('data.tabNumber');
                var bTabNumber = b.get('data.tabNumber');

                if (!Ember.isEmpty(aTabNumber) && Ember.isEmpty(bTabNumber) || !Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber) && aTabNumber < bTabNumber) {
                    // Doc A is lower in order than Doc B based on tab number
                    return -1;
                } else if (Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber) || !Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber) && aTabNumber > bTabNumber) {
                    // Doc A is higher in order than Doc B based on tab number
                    return 1;
                } else {
                    // Doc A is equal in order to Doc B based on tab number - check against doc type now
                    var sortedTypesNames = _this3.get('fileTypes').mapBy('value');
                    var aDocType = a.get('data.docType');
                    var bDocType = b.get('data.docType');
                    var aDocTypeIndex = sortedTypesNames.indexOf(aDocType);
                    var bDocTypeIndex = sortedTypesNames.indexOf(bDocType);

                    if (!Ember.isEmpty(aDocType) && Ember.isEmpty(bDocType) || !Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) && aDocTypeIndex < bDocTypeIndex) {
                        // Doc A is lower in order than Doc B based on doc type's order
                        return -1;
                    } else if (Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) || !Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) && aDocTypeIndex > bDocTypeIndex) {
                        // Doc A is higher in order than Doc B based on doc type
                        return 1;
                    } else {
                        // Doc A is equal in order to Doc B based on doc type - check against name now
                        return a.get('data.name') < b.get('data.name') ? -1 : 1;
                    }
                }
            });

            return sortedList;
        }),

        showModal: false,
        showDeleteModal: false,

        actions: {
            setFormIsOpenFlag: function setFormIsOpenFlag(value) {
                this.send('modifyFormIsOpen', value);
            },
            newUpload: function newUpload() {
                this.set('showUploadForm', true);
            },
            beforeUpload: function beforeUpload(filesToUpload) {},
            afterUpload: function afterUpload() {
                this.set('showUploadForm', false);
                this.send('fetchSubject');
                this.send('fetch');
                this.get('notify').success('Documents uploaded');
            },
            errorUpload: function errorUpload() {
                this.get('notify').error('There was a problem uploading the files');
            },
            cancelUpload: function cancelUpload() {
                this.set('showUploadForm', false);
            },
            setSelectionTo: function setSelectionTo(item) {
                this.set('documentSelection', [item]);
            },
            selectionStateChanged: function selectionStateChanged(item, evt) {
                // console.info('selectionStateChanged');
                if (evt.target.checked) {
                    // add to the list
                    item.set('isSelectedForEdit', true);
                } else {
                    // remove from the list
                    item.set('isSelectedForEdit', undefined);
                }
            },
            selectAll: function selectAll() {
                this.get('documents').forEach(function (document) {
                    return document.set('isSelectedForEdit', true);
                });
            },
            selectNone: function selectNone() {
                this.get('documents').forEach(function (document) {
                    return document.set('isSelectedForEdit', undefined);
                });
            },
            showEditModal: function showEditModal() {
                var documentSelection = this.get('documentSelection');

                if (documentSelection.length > 0) {
                    this.set('showModal', true);
                    this.send('modifyFormIsOpen', true);
                }
            },
            showDeleteModal: function showDeleteModal() {
                var documentSelection = this.get('documentSelection');

                if (documentSelection.length > 0) {
                    this.set('showDeleteModal', true);
                    this.send('modifyFormIsOpen', true);
                }
            },
            clearDocumentSelection: function clearDocumentSelection() {
                this.set('documentSelection', []);
            },
            dropChanges: function dropChanges() {
                if (!this.get('saving')) {
                    // dropchanges -> let\'s rollback!
                    var docs = this.get('sortedDocumentSelectionCopy');

                    docs.forEach(function (doc) {
                        if (doc.get('hasChanges')) {
                            doc.discardChanges();
                        }
                    });
                }
            },
            saveChanges: function saveChanges() {
                // allow upload file directly from here
                if (Ember.$('input[type=file]').get(0) && Ember.$('input[type=file]').get(0).value) {
                    this.get('documentEditTable').send('upload');

                    // The after Upload will trigger the applyChanges
                } else {
                    return this.send('applyChanges');
                }
            },
            applyChanges: function applyChanges() {
                var _this4 = this;

                var docs = this.get('sortedDocumentSelectionCopy');

                this.set('saving', true);
                this.set('showModal', false);

                var promises = [];
                var deletePromises = [];

                docs.forEach(function (doc) {
                    if (doc.get('isDirty')) {
                        var originalDoc = _this4.get('documentSelection').findBy('id', doc.get('id'));
                        var oldResourcesHaveTags = originalDoc.hasMany('resourceHasTags').value();

                        if (oldResourcesHaveTags) {
                            deletePromises.pushObjects(oldResourcesHaveTags.map(function (rht) {
                                return rht.destroyRecord();
                            }));
                        }
                    }
                });

                return Ember.RSVP.all(deletePromises).then(function () {
                    docs.forEach(function (doc) {
                        if (doc.get('isDirty')) {
                            promises.push(doc.save());
                            var originalDoc = _this4.get('documentSelection').findBy('id', doc.get('id'));
                            var selectedTags = doc.get('tags') || [];

                            selectedTags.forEach(function (tag) {
                                var rht = _this4.store.createRecord('resource-has-tag', {
                                    tag: tag,
                                    resource: originalDoc
                                });

                                promises.push(rht.save());
                            });
                        }
                    });

                    return Ember.RSVP.Promise.all(promises).then(function () {
                        _this4.set('saving', false);
                        _this4.send('clearDocumentSelection');
                        _this4.get('notify').success('Document was updated');
                        _this4.send('fetchSubject');
                        _this4.send('fetch');
                    });
                });
            },
            applyDelete: function applyDelete() {
                var _this5 = this;

                var docs = this.get('documentSelection');

                this.set('saving', true);
                this.set('showDeleteModal', false);
                var promises = docs.map(function (doc) {
                    return doc.destroyRecord();
                });

                return Ember.RSVP.Promise.all(promises).then(function () {
                    _this5.set('saving', false);
                    _this5.get('notify').success('Files removed successfully');
                    _this5.send('fetchSubject');
                    _this5.send('fetch');
                });
            },
            deleteFile: function deleteFile() {
                // console.warn('delete file -> ', item);
            },
            showKeywordListSelectionModal: function showKeywordListSelectionModal() {
                var notOcrDocIds = this.get('documentSelection').rejectBy('ocrIndexStatus', 'done').length;

                if (notOcrDocIds > 0) {
                    this.get('notify').error('You cannot generate a keyword search for documents that have not been OCRed');

                    return;
                }
                this.set('showKeywordListSelection', true);
            },
            generateKeywordsSearchForSubject: function generateKeywordsSearchForSubject(keywordListId, keywords) {
                var docIds = this.get('documentSelection').filterBy('ocrIndexStatus', 'done').map(function (doc) {
                    return doc.id;
                });

                var url = this.get('router').urlFor('matter.documents.keywords-list-search', {
                    queryParams: {
                        kl: keywordListId ? keywordListId.id : null,
                        kw: keywords,
                        s: this.subject.id,
                        docs: docIds.join(',')
                    }
                });

                window.open(url, '_blank');
            },


            // actions called during replace a document
            beforeReplaceUpload: function beforeReplaceUpload(filesToUpload) {
                this.get('notify').warning('Starting to upload the file to replace');
                var documentToUpdate = this.get('sortedDocumentSelectionCopy.firstObject');

                filesToUpload.forEach(function (f) {
                    // Set the overwrite ID
                    f.set('overwriteDocumentId', documentToUpdate.get('id'));
                });
            },
            afterReplaceUpload: function afterReplaceUpload(response) {
                var _this6 = this;

                response.documents.forEach(function (d) {
                    var doc = _this6.get('sortedDocumentSelectionCopy').findBy('id', d.id);

                    if (doc) {
                        doc.set('type', d.type);
                        doc.set('size', d.size);
                        doc.set('file_name', d.file_name);
                    }
                });
                this.get('notify').success('Document uploaded');

                // to trigger the update after the upload
                this.get('sortedDocumentSelectionCopy').forEach(function (x) {
                    return x.set('changed', true);
                });
                this.send('applyChanges');
                this.set('showModal', false);
                Ember.run.later(function () {
                    _this6.send('clearDocumentSelection');
                }, 1000);
            },
            errorReplaceUpload: function errorReplaceUpload() {
                this.get('notify').error('There was a problem uploading the files to replace');
            },
            cancelReplaceUpload: function cancelReplaceUpload() {
                // just clear the list of file to upload
                Ember.$('input[type=file]').get(0).value = '';
            },
            markDocumentAsViewed: function markDocumentAsViewed(item) {
                item.set('hasBeenViewed', 1);
            },
            sendToReport: function sendToReport(item, report) {
                var _this7 = this;

                item.set('report', report);
                item.save().then(function () {
                    _this7.send('fetch');
                });
            },
            reorderTabs: function reorderTabs(sortedTabs) {
                var _this8 = this;

                var promises = [];

                sortedTabs.forEach(function (tab, index) {
                    if (tab.get('tabNumber') === index + 1) {
                        return;
                    }
                    tab.set('tabNumber', index + 1);
                    promises.pushObject(tab.save());
                });
                Ember.RSVP.all(promises).then(function () {
                    _this8.send('fetch');
                    _this8.get('notify').success('The tabs have new numbers');
                });
            },
            reportDocumentsSelect: function reportDocumentsSelect(selected) {
                this.get('reportDocuments').forEach(function (doc) {
                    return doc.set('isSelectedForEdit', selected);
                });
            },
            reportTabDocumentsSelect: function reportTabDocumentsSelect(selected) {
                this.get('reportTabDocuments').forEach(function (doc) {
                    return doc.set('isSelectedForEdit', selected);
                });
            },
            groupedReportTabDocumentsSelect: function groupedReportTabDocumentsSelect(reportName, selected) {
                this.get('groupedReportTabDocuments')[reportName].forEach(function (doc) {
                    return doc.set('isSelectedForEdit', selected);
                });
            },
            groupedDocumentsSelect: function groupedDocumentsSelect(docType, selected) {
                this.get('groupedDocuments')[docType].forEach(function (doc) {
                    return doc.set('isSelectedForEdit', selected);
                });
            }
        }
    });
});