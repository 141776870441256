define('tm3/pods/components/crm/contacts/info/matters/matter-connection/component', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment', 'tm-common/utils/get-app-url', 'ember-component-inbound-actions/inbound-actions'], function (exports, _error, _environment, _getAppUrl, _inboundActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var MyPromiseProxy = Ember.Object.extend(Ember.PromiseProxyMixin);

    exports.default = Ember.Component.extend(_inboundActions.default, _error.default, {
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),

        billingProxy: Ember.computed('promise', {
            get: function get() {
                var promise = Ember.get(this, 'promise');
                return Ember.isPresent(promise) ? MyPromiseProxy.create({ promise: promise }) : null;
            }
        }),

        tagName: 'li',
        classNames: ['clearfix'],
        showMore: Ember.computed.alias('item.showMore'),

        actions: {
            /**
             * reveal the edit form for this connection
             *
             * @param connection
             */
            showSave: function showSave(connection) {
                this.set('currentConnection', connection);
                this.set('showForm', true);
            },


            /**
             * delete a matter has user record
             *
             * @param connection
             * @returns {boolean}
             */
            delete: function _delete(connection) {
                var _this = this;

                var msg = 'Case Connection Removed';
                if (connection.modelName === 'firm-has-matter') {
                    msg = 'Account Connection Removed';
                }
                connection.destroyRecord().then(function () {
                    _this.get('notify').success(msg);
                }, function (reason) {
                    this.validationReport(reason);
                });
            },


            /**
             * Get information about the billing and hours
             */
            getBillingInfo: function getBillingInfo() {
                this.toggleProperty('showMore');
                if (this.get('showMore')) {
                    Ember.set(this, 'promise', this.get('ajax').request((0, _getAppUrl.default)(_environment.default, '/matters/' + this.get('item.matter.id') + '/billing_hours_info')));
                }
            }
        }
    });
});