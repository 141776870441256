define('tm3/pods/components/document-url/component', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        metrics: Ember.inject.service(),
        ajax: Ember.inject.service(),
        attributeBindings: ['title', 'href', 'download'],
        title: 'Download the document',
        text: false,
        icon: null,
        afterIcon: null,
        searchable: false,
        click: function click(event) {
            var _this = this;

            event.preventDefault();
            Ember.get(this, 'metrics').trackEvent({
                category: 'Documents',
                action: 'download',
                label: this.get('docId')
            });

            if (this.get('markDocumentAsViewed')) {
                this.get('markDocumentAsViewed')();
            }

            this.get('ajax').post('/users/generate-nonce', {
                data: {
                    document_id: this.get('docId')
                },
                contentType: 'application/json'
            }).then(function (response) {
                var nonceDownloadUrl = response.nonce_download_url;
                var params = new URLSearchParams({
                    token: response.token,
                    subject_id: _this.get('subjectId'),
                    searchable: _this.get('searchable')
                }).toString();

                var url = '' + _environment.default.APP.documentDownloadURL + _this.get('docId') + '/download?' + params;

                var $downloadLink = document.createElement('a');
                $downloadLink.setAttribute('target', "_blank");
                $downloadLink.setAttribute('href', url);
                // firefox hack doesn`t allow click trigger on href
                var ev = document.createEvent('MouseEvents');
                ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                // Fire event
                $downloadLink.dispatchEvent(ev);
            }).catch(function (error) {
                console.error('Error generating download nonce:', error);

                // Handle error - display message to user, etc.
                _this.get('notify').error('Error initiating download. Please try again.');
            });

            return true;
        },


        href: Ember.computed('docId', function () {
            return 'documents/' + this.get('docId') + '/download';
        }),
        download: 'download.php',
        tagName: 'a'
    });
});