define('tm3/helpers/markdown', ['exports', 'tm-common/helpers/markdown'], function (exports, _markdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _markdown.default;
    }
  });
  Object.defineProperty(exports, 'markdown', {
    enumerable: true,
    get: function () {
      return _markdown.markdown;
    }
  });
});