define('tm3/pods/report/info/billing-report-by-person/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'billing_report_by_person',

        queryParams: {
            start: {
                refreshModel: false
            },
            end: {
                refreshModel: false
            },
            employeeId: {
                refreshModel: false
            },
            field: {
                refreshModel: false
            },
            onlyBilled: {
                refreshModel: false
            },
            cases: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params+
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    start: params.start,
                    end: params.end,
                    group: params.field,
                    employee: params.employeeId,
                    only_billed: params.onlyBilled ? '1' : '0',
                    cases: params.cases
                }),
                matters: this.store.query('matter', {
                    id: params.cases
                }),
                field: params.field,
                employeeId: params.employeeId,
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                employees: this.store.query('employee', {
                    sort: 'first_name,last_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer']
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);

            var excludedDepartments = ['Technology', 'Accounting', 'Sales and Critical Research'];
            var filteredEmployees = resolved.employees.filter(function (e) {
                return excludedDepartments.indexOf(e.get('department')) === -1;
            });
            controller.set('employees', filteredEmployees);
            if (resolved.employeeId) {
                controller.set('selectedEmployee', filteredEmployees.findBy('id', resolved.employeeId));
            }

            controller.set('selectedCases', resolved.matters);

            controller.set('field', resolved.field);
            controller.set('fieldFromRequest', resolved.field);
            if (resolved.field) {
                var groupFieldsOptions = controller.get('groupFieldsOptions');

                var selectedOption = groupFieldsOptions.findBy('value', resolved.field);
                controller.set('selectedField', selectedOption);
            }
        },

        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});