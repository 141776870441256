define('tm3/pods/components/table-filter-components/task-status/component', ['exports', 'tm3/utils/task-statuses'], function (exports, _taskStatuses) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            if (Ember.typeOf(this.get('column.advFilterValue')) !== 'array') {
                return;
            }
            this.set('selectedStatuses', this.get('statuses').filter(function (s) {
                return _this.get('column.advFilterValue').includes(s.get('value'));
            }));
        },


        statuses: _taskStatuses.TaskStatuses,

        actions: {
            selectStatus: function selectStatus(newStatuses) {
                this.set('column.advFilterValue', newStatuses.mapBy('value'));
                this.set('selectedStatuses', newStatuses);
            }
        }
    });
});