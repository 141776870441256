define('tm3/pods/components/my-tasks/cell/status/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        'data-test-component': 'my-tasks-status-table-cell',
        tagName: 'div',

        label: Ember.computed('value', function () {
            switch (this.get('value')) {
                case 'I':
                    return 'Not Started';
                case 'P':
                    return 'Waiting';
                case 'C':
                    return 'Complete';
                case 'R':
                    return 'Complete and Reported';
                default:
                    return null;
            }
        })
    });
});