define('tm3/pods/components/search-db/search-result/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        media: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        classNames: ['row', 'search-result'], //, '.loader-overlay-container'

        canEdit: false,
        canCreateMemo: false,
        db: null,

        showTagsColumn: Ember.computed('currentTab', function () {
            var currentTab = this.get('currentTab');
            return currentTab === 'general';
        }),

        firsColumnClass: Ember.computed('showDetails', 'showTagsColumn', function () {
            if (this.get('showDetails')) {
                if (this.get('showTagsColumn')) {
                    return 'col-md-3';
                }
                return 'col-md-4';
            }
            return 'col-md-10';
        }),

        countMemos: Ember.computed('db.dbMemos.[]', 'currentMatter.id', function () {
            return this.get('db.dbMemos').filterBy('matter.id', this.get('currentMatter.id')).get('length');
        }),

        maxTagLength: Ember.computed('media.isTablet', 'media.isDesktop', 'media.isJumbo', function () {
            var media = this.get('media');
            if (media.get('isJumbo')) {
                return 30;
            }
            if (media.get('isDesktop')) {
                return 20;
            }
            if (media.get('isTablet') || media.get('isMobile')) {
                return 150;
            }
        }),

        actions: {
            toggleDatabaseToSelection: function toggleDatabaseToSelection() {
                this.sendAction('toggleDatabaseToSelection');
            },
            deleteDb: function deleteDb() {
                this.sendAction('deleteDb');
            },
            createDbMemo: function createDbMemo() {
                this.sendAction('createDbMemo');
            },
            showMemos: function showMemos() {
                this.sendAction('showMemos');
            }
        }
    });
});