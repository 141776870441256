define('tm3/pods/components/matter/x-task-filter/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        session: Ember.inject.service(),
        reportBuilder: Ember.inject.service(),

        // Properties on the filter component
        assignedToId: '',
        createdById: '',
        assignedTo: null,
        dueFrom: '',
        dueTo: '',
        dueOn: '',
        noDue: false,
        selectedState: '',
        state: '',
        selectedCounty: '',
        county: '',
        matterTypeId: '',
        matterType: '',
        onlyUrgent: false,
        completedOnly: false,

        assignedToIdObserver: Ember.observer('assignedTo', function () {
            var assignedList = this.get('assignedTo');
            if (Array.isArray(assignedList) && assignedList.length) {
                var ids = assignedList.mapBy('id');
                this.set('assignedToId', ids);
            } else {
                this.set('assignedToId', []);
            }
        }),

        createdByIdObserver: Ember.observer('createdBy', function () {
            var creatorList = this.get('createdBy');
            if (Array.isArray(creatorList) && creatorList.length) {
                var ids = creatorList.mapBy('id');
                this.set('createdById', ids);
            } else {
                this.set('createdById', []);
            }
        }),

        matterTypeIdObserver: Ember.observer('matterType', function () {
            var newID = this.get('matterType.id');
            if (newID !== undefined) {
                this.set('matterTypeId', newID);
            } else {
                this.set('matterTypeId', '');
            }
        }),

        selectedStateObserver: Ember.observer('selectedState', function () {
            var newState = this.get('selectedState');
            if (newState !== undefined) {
                this.set('state', newState);
            } else {
                this.set('state', '');
            }
        }),
        selectedCountyObserver: Ember.observer('selectedCounty', function () {
            var newCounty = this.get('selectedCounty');
            if (newCounty !== undefined) {
                this.set('county', newCounty);
            } else {
                this.set('county', '');
            }
        }),
        extraEmployeeOption: Ember.ArrayProxy.create({
            content: [{ id: 'null', fullName: 'All Unassigned' }]
        }),
        employeesExtended: Ember.computed.union('extraEmployeeOption', 'employees'),

        actions: {
            clearFilter: function clearFilter() {
                this.setProperties({
                    createdBy: '',
                    assignedTo: '',
                    dueFrom: '',
                    dueTo: '',
                    dueOn: '',
                    noDue: false,
                    state: '',
                    county: '',
                    matterType: '',
                    onlyUrgent: false,
                    completedOnly: false
                });
            },
            exportExcel: function exportExcel() {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'tasks',
                            format: 'excel',
                            style: 'misc'
                        },
                        custom: {
                            filters: {
                                created_by_id: this.get('createdById'),
                                assigned_to_id: this.get('assignedToId').length > 0 ? this.get('assignedToId') : undefined,
                                no_due: this.get('noDue'),
                                due_from: this.get('dueFrom'),
                                due_to: this.get('dueTo'),
                                state: this.get('state'),
                                county: this.get('county'),
                                matter_type: this.get('matterTypeId'),
                                only_urgent: this.get('onlyUrgent'),
                                completed_only: this.get('completedOnly')
                            },
                            sort_by: this.get('sortField')
                        }
                    }
                };
                this.get('reportBuilder').executeReport(jsonObject);
            }
        }
    });
});