define('tm3/pods/matter/subjects/copy/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        store: Ember.inject.service(),

        queryParams: {
            ids: {
                refreshModel: false
            }
        },

        model: function model(params) {
            var _this = this;

            return Ember.RSVP.hash({
                subjects: this.get('store').query('subject', {
                    resource_id: (params.ids || '').split(','),
                    with: 'matters'
                }).then(function (result) {
                    return result.rejectBy('matter.id', _this.get('currentMatter.id'));
                })
            });
        },
        resetController: function resetController(controller) {
            controller.reset();
        }
    });
});