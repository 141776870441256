define('tm3/pods/components/subjects/copy-subjects-button/component', ['exports', 'tm3/pods/components/subjects/copy-subjects-button/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        notify: Ember.inject.service(),
        store: Ember.inject.service(),

        selectedRows: null,
        styleForJSI: false,

        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            return this.get('store').query('matter', {
                'name||plaintiff||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        },


        actions: {
            toggleCopySubjectsModal: function toggleCopySubjectsModal() {
                var selectedRows = this.get('selectedRows');

                if (selectedRows.length > 0) {
                    this.set('showModal', true);
                } else {
                    this.get('notify').error('You must selected at least one subject.');
                }
            },

            searchMatters: function searchMatters(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchMatters, term, resolve, reject, 600);
                });
            }
        }
    });
});