define("tm3/pods/components/file-download-url/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "",
        ajax: Ember.inject.service(),
        notify: Ember.inject.service(),

        showName: false,

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        actions: {
            initiateDownload: function initiateDownload() {
                var _this = this;

                this.get("ajax").post("/users/generate-nonce", {
                    contentType: "application/json"
                }).then(function (response) {
                    var ENV = _this.get('ENV');
                    var download = ENV.APP.documentDownloadURL.replace('documents', 'files');
                    var nonceDownloadUrl = download + "/" + _this.get('id') + "/download?token=" + response.token;

                    var $downloadLink = document.createElement('a');
                    $downloadLink.setAttribute('target', "_blank");
                    $downloadLink.setAttribute('href', nonceDownloadUrl);
                    // firefox hack doesn`t allow click trigger on href
                    var ev = document.createEvent('MouseEvents');
                    ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    // Fire event
                    $downloadLink.dispatchEvent(ev);
                }).catch(function (error) {
                    console.error("Error generating download nonce:", error);

                    // Handle error - display message to user, etc.
                    _this.get("notify").error("Error initiating download. Please try again.");
                });
            }
        }
    });
});