define("tm3/pods/components/avatar-cropper/component", ["exports", "tm3/config/environment", "tm-common/utils/get-app-url", "ember-component-inbound-actions/inbound-actions"], function (exports, _environment, _getAppUrl, _inboundActions) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var MAX_PREVIEW_HEIGHT = 500;
    var AVATAR_SIZE = 120;

    /**
     * Polyfill of toBlob for browsers that don't support it
     */
    if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
            value: function value(callback, type, quality) {
                var binStr = atob(this.toDataURL(type, quality).split(",")[1]);
                var len = binStr.length;
                var arr = new Uint8Array(len);

                for (var i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                callback(new Blob([arr], { type: type || "image/png" }));
            }
        });
    }

    /**
     * Based on ember-cli-image-cropper
     *
     * needed to copy in order to extend some properties not
     * present on the original addon
     *
     *
     * Example:
     *
     * {{avatar-cropper fileId=mode.avatarId
     *  afterUpload='afterUpload'}}
     */
    exports.default = Ember.Component.extend(_inboundActions.default, {
        ajax: Ember.inject.service(),
        notify: Ember.inject.service(),

        /**
         * @property
         *
         * to send back to the context the file id after upload
         */
        fileId: null,

        /**
         * @property
         *
         * after uploaded just show the preview avatar
         */
        uploaded: Ember.computed.notEmpty("fileId"),

        generateAvatarUrl: function generateAvatarUrl() {
            var _this2 = this;

            if (Ember.isEmpty(this.get("fileId"))) {
                this.set("previewAvatar", null);
                return;
            }
            this.get("ajax").post("/users/generate-nonce", {
                contentType: "application/json"
            }).then(function (response) {
                var nonceDownloadUrl = (0, _getAppUrl.default)(_environment.default, "/files/" + _this2.get('fileId') + "/download?token=" + response.token);
                _this2.set("previewAvatar", nonceDownloadUrl);
            }).catch(function (error) {
                console.error("Error generating download nonce:", error);

                // Handle error - display message to user, etc.
                _this2.get("notify").error("Error initiating download. Please try again.");
            });
        },


        previewAvatarObserver: Ember.observer("fileId", function () {
            this.generateAvatarUrl();
        }),

        // cropper configs
        previewClass: "",
        cropperContainer: ".cropper-container > img",
        aspectRatio: 1,
        crop: null,
        viewMode: 0,
        dragMode: "none",
        responsive: true,
        checkCrossOrigin: true,
        background: true,
        modal: true,
        guides: true,
        highlight: true,
        autoCrop: true,
        autoCropArea: 0.8,
        dragDrop: true,
        movable: true,
        resizable: false,
        zoomable: false,
        zoomOnWheel: true,
        zoomOnTouch: true,
        toggleDragModeOnDblclick: true,
        rotateable: true,
        minContainerWidth: 200,
        minContainerHeight: 200,
        minCropBoxWidth: 100,
        minCropBoxHeight: 100,
        build: null,
        built: null,
        dragStart: null,
        dragMove: null,
        dragEnd: null,
        zoomin: null,
        zoomout: null,
        cropBoxResizable: true,

        /**
         * @property
         * result of the crop process
         */
        croppedAvatar: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.generateAvatarUrl();
        },


        // initialize cropper on did insert element
        initImageCropper: Ember.on("didRender", function () {
            var _this = this;
            _this.$(_this.get("cropperContainer")).cropper({
                aspectRatio: _this.get("aspectRatio"),
                crop: _this.get("crop"),
                preview: _this.get("previewClass"),
                viewMode: _this.get("viewMode"),
                dragMode: _this.get("dragMode"),
                responsive: _this.get("responsive"),
                checkCrossOrigin: _this.get("checkCrossOrigin"),
                toggleDragModeOnDblclick: _this.get("toggleDragModeOnDblclick"),
                background: _this.get("background"),
                modal: _this.get("modal"),
                guides: _this.get("guides"),
                highlight: _this.get("highlight"),
                autoCrop: _this.get("autoCrop"),
                autoCropArea: _this.get("autoCropArea"),
                dragDrop: _this.get("dragDrop"),
                movable: _this.get("movable"),
                resizable: _this.get("resizable"),
                zoomable: _this.get("zoomable"),
                zoomOnWheel: _this.get("zoomOnWheel"),
                zoomOnTouch: _this.get("zoomOnTouch"),
                rotateable: _this.get("rotateable"),
                minContainerWidth: _this.get("minContainerWidth"),
                minContainerHeight: _this.get("minContainerHeight"),
                minCropBoxWidth: _this.get("minCropBoxWidth"),
                minCropBoxHeight: _this.get("minCropBoxHeight"),
                build: _this.get("build"),
                built: _this.get("built"),
                dragStart: _this.get("dragStart"),
                dragMove: _this.get("dragMove"),
                dragEnd: _this.get("dragEnd"),
                zoomin: _this.get("zoomin"),
                zoomout: _this.get("zoomout"),
                cropBoxResizable: _this.get("cropBoxResizable")
            });
        }),

        /**
         * when the file is selected and ready for display
         */
        ready: false,

        /**
         * selected image by the x-filefield component
         */
        selectedImage: null,

        /**
         * action name used to notify the controller
         * about the component finished the upload
         *
         * @event
         */
        afterUpload: "",

        /**
         * @property if we want to call the uploadAvatar method from the outside
         * using the inbound actions
         */
        hideUploadButton: false,

        /**
         * Process new selected image
         *
         * it creates a canvas to resize to a maximum size and
         * replace the url of the cropper plugin so the user
         * can select the crop area.
         */
        fileChanged: function fileChanged(selectedImage) {
            this.set("selectedImage", selectedImage);
            if (!selectedImage) {
                return;
            }

            var self = this;
            var fr = new FileReader();

            fr.onload = function () {
                var container = self.$(self.get("cropperContainer"));

                var image = new Image();
                image.onload = function () {
                    // resize to a maximum height
                    var canvas = document.getElementById("avatar-canvas");

                    if (image.height > MAX_PREVIEW_HEIGHT) {
                        image.width *= MAX_PREVIEW_HEIGHT / image.height;
                        image.height = MAX_PREVIEW_HEIGHT;
                    }

                    var ctx = canvas.getContext("2d");
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0, image.width, image.height);

                    container.cropper("replace", canvas.toDataURL());
                    self.set("ready", true);
                };
                image.onerror = function () {
                    self.get("notify").error("Please select a valid image");
                    self.set("ready", false);
                };
                image.src = fr.result;
            };

            fr.readAsDataURL(selectedImage.file);
        },


        actions: {
            selectImage: function selectImage(files) {
                this.fileChanged(files.length === 0 ? null : files[0]);
            },

            /**
             * use the cropper plugin to generate an avatar of AVATAR_SIZE x AVATAR_SIZE
             */
            getCroppedAvatar: function getCroppedAvatar() {
                var container = this.$(this.get("cropperContainer"));
                var croppedImage = container.cropper("getCroppedCanvas", {
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE
                });
                this.set("croppedAvatar", croppedImage);
            },

            /**
             * uses the cropped canvas to get the dataURL to upload to
             * the server
             */
            uploadAvatar: function uploadAvatar() {
                var self = this;
                var container = this.$(this.get("cropperContainer"));
                var croppedImage = container.cropper("getCroppedCanvas", {
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE
                });
                if (croppedImage) {
                    if (typeof croppedImage.toBlob !== "function") {
                        self.sendAction("afterUpload", self.get("fileId"));
                        return;
                    }
                    croppedImage.toBlob(function (blob) {
                        var formData = new FormData();

                        var file = self.get("selectedImage.file");

                        var baseName = file.name.split(".")[0];

                        formData.append("files", blob, baseName + ".png");

                        self.get("ajax").post("files", {
                            data: formData,
                            processData: false,
                            contentType: false
                        }).then(function (response) {
                            self.set("fileId", response.files[0].id);
                            self.get("notify").success("Avatar has been uploaded correctly");
                            self.sendAction("afterUpload", self.get("fileId"));
                        }, function () {
                            self.get("notify").error("Upload error");
                        });
                    });
                }
            },
            changeAvatar: function changeAvatar() {
                var self = this;
                self.set("fileId", null);
            }
        }
    });
});