define('tm3/validators/quick-scrape', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        // selectedSubject: [validatePresence({ presence: true, message: 'Subject is required' })],
        // subjectFirst: [validatePresence({ presence: true, message: 'First name is required'})],
        // subjectLast: [validatePresence({ presence: true, message: 'Last name is required' })],
        selectedBudget: [(0, _validators.validatePresence)(true)],
        quickUrl: (0, _validators.validateFormat)({ type: 'url', message: 'URL must be a valid URL' }),
        quickAmount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })]
    };
});