define('tm3/pods/components/subject-conflict-check/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        store: Ember.inject.service(),

        router: Ember.inject.service(),

        subjectNames: Ember.computed('fullName', 'shouldSearch', function () {
            return this.get('shouldSearch') ? [this.get('fullName')] : [];
        }),

        fullName: Ember.computed('firstName', 'lastName', function () {
            var first = (this.get('firstName') || '').trim();
            var last = (this.get('lastName') || '').trim();

            return first && last ? last + ', ' + first : first || last;
        }),

        moreLink: Ember.computed('firstName', 'lastName', function () {
            var firstName = (this.get('firstName') || '').trim();
            var name = (this.get('lastName') || '').trim();

            if (!firstName && !name) {
                return null;
            }

            return this.get('router').urlFor('conflicts', {
                queryParams: {
                    type: 'subjects',
                    firstName: firstName,
                    name: name
                }
            });
        }),

        shouldSearch: Ember.computed('fullName', function () {
            return this.get('fullName').length >= 3;
        })
    });
});